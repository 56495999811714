import React from "react";

export default function WeatherTemperature(props) {
    //let [unit, setUnit] = useState ("celsius");
   //function showFahrenheit(event){
     //   event.preventDefault();
     //   setUnit("fahrenheit");
    //}

//function showCelsius(event){
   // event.preventDefault();
    //setUnit("celsius");
//}

//function fahrenheit() {
//return (props.celsius * 9) / 5 + 32;
//}

    //if (unit === "celsius") {
return (
  <div className="WeatherTemeprature">
    <span className="temperature">{Math.round(props.celsius)}</span>
    <span className="unit">
      °C  </span>
  </div>
      // | {" "} <a href="/" onClick={showFahrenheit}>°F </a>
  
);
   // } else {
    //    return (
     //          <div className="WeatherTemeprature">
    //<span className="temperature">{Math.round(fahrenheit())}</span>
    //<span className="unit"><a href="/" onClick={showCelsius}>
     // °C </a>  {" "} | °F </span>
  //</div>
    //);

//  } 
}